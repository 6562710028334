import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Button } from '@driftt/tide-core';

import './CopyGifButton.css';

function CopyGifButton({ disabled, gifSrc }) {
  const [justCopied, setJustCopied] = useState(false);

  const handleClick = (e) => {
    if (justCopied) {
      return;
    }

    const onCopy = (e) => {
      const html = [
        '<img src="' + gifSrc + '">',
        // Add the src as hidden text for apps
        // (like Slack) that don't support pasting GIFs.
        '<div style="display: none" tabIndex="-1" aria-hidden="true">',
        gifSrc,
        '</div>',
        // Strangely, this trailing space is required for pasting into Slack.
        ' ',
      ].join('');

      e.clipboardData.setData('text/html', html);
      e.clipboardData.setData('text/plain', gifSrc);
      e.preventDefault();
    };

    document.addEventListener('copy', onCopy);
    document.execCommand('copy');
    document.removeEventListener('copy', onCopy);

    setJustCopied(true);
    setTimeout(() => setJustCopied(false), 3000);
  };

  return (
    <Button className="copy-gif-button" disabled={disabled} onClick={handleClick} size="medium">
      {justCopied ? 'Copied!' : 'Copy GIF'}
    </Button>
  );
}

CopyGifButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  gifSrc: PropTypes.string,
};

export default CopyGifButton;
