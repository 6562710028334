import React, { useContext,useState } from 'react';

import PropTypes from 'prop-types';

import { ActionDropdown, Button, ToolTip } from '@driftt/tide-core';

import { deleteVideo, getVideoDownloadUrls } from 'api';
import ConfirmDeleteModal from 'components/confirmDeleteModal';
import FullPageSpinner from 'components/fullPageSpinner';
import { UserContext } from 'context/UserContext';

import './OptionsMenuButton.css';

function OptionsMenuButton({ isDownloadDisabled, videoId }) {
  const { createUrlPath } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleConfirmDelete = async () => {
    setShowModal(false);
    setShowSpinner(true);

    try {
      await deleteVideo({ videoId });
      window.location.href = createUrlPath('/video');
    } catch (e) {
      Error(e);
      setShowSpinner(false);
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  return (
    <>
      <ActionDropdown
        className="options-menu-button"
        trigger={
          <ToolTip flow="up" render={() => <div>Options</div>}>
            <Button type="seconary" icon="meatballs" />
          </ToolTip>
        }
        actions={[
          {
            icon: 'download-2',
            label: 'Download GIF',
            disabled: isDownloadDisabled,
            action: (hideDropdown) => {
              getVideoDownloadUrls({ videoId }).then(({ url }) => (window.open().location = url));
              hideDropdown();
            },
          },
          {
            icon: 'trash',
            label: 'Delete GIF',
            action: (hideDropdown) => {
              setShowModal(true);
              hideDropdown();
            },
          },
        ]}
      />
      <ConfirmDeleteModal
        isOpen={showModal}
        onClickConfirm={handleConfirmDelete}
        onClickCancel={handleCancelDelete}
        target="GIF"
      />
      <FullPageSpinner show={showSpinner} />
    </>
  );
}

OptionsMenuButton.propTypes = {
  isDownloadDisabled: PropTypes.bool.isRequired,
  videoId: PropTypes.number.isRequired,
};

export default OptionsMenuButton;
