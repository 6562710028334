import React, { useState } from 'react'

import accidentImg from 'assets/images/GIF-Accident@2x.png'
import failureImg from 'assets/images/GIF-Failure@2x.png'
import PropTypes from 'prop-types'

import VideoLoadingPlaceholder from './VideoLoadingPlaceholder'

import './GifPreview.css'

function GifPreview({ gifSrc, hadTranscodeError }) {
  const [hadLoadError, setHadLoadError] = useState(false)
  const handleLoadError = () => setHadLoadError(true)
  const anyError = hadLoadError || hadTranscodeError

  return (
    <article className="gif-preview">
      {anyError && (
        <img
          className="gif-failure-image"
          src={hadTranscodeError ? failureImg : accidentImg}
          alt="failed to load gif"
        />
      )}
      {!anyError && !gifSrc && <VideoLoadingPlaceholder />}
      {!anyError && gifSrc && (
        <img className="gif-preview-image" src={gifSrc} onError={handleLoadError} alt="" />
      )}
    </article>
  )
}

GifPreview.propTypes = {
  gifSrc: PropTypes.string,
  hadTranscodeError: PropTypes.bool.isRequired,
}

export default GifPreview
