import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CircleLoader } from '@driftt/tide-core';

import { fetchVideo, fetchVideoIdForHash } from 'api';

import ShareGifPage from './shareGif/ShareGifPage';
import ShareVideoApp from './shareVideo/ShareVideoApp';

const SharePage = () => {
  const { id: slugVideoId } = useParams<{ id: string }>();
  const [currentVideoId, setCurrentVideoId] = useState<number | null>(null);
  const [isGif, setIsGif] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  

  const getVideoIdForHash = async (hash: string) => {
    try {
      const {
        data: { id: numericVideoId },
      }: { data: { id: number } } = await fetchVideoIdForHash(hash);
      setCurrentVideoId(numericVideoId);
    } catch (e) {
      Error(`Error retrieving videoId: ${(e as any).message}`);
    }
  };

  useEffect(() => {
    if (slugVideoId) {
      if (slugVideoId.match(/^[0-9]+$/g)) {
        // video ID is in the URL already
        setCurrentVideoId(parseInt(slugVideoId));
      } else {
        (async function () {
          // unified app URL with hash
          await getVideoIdForHash(slugVideoId);
        })();
      }
    }
  }, [slugVideoId]);

  useEffect(() => {
    const fetchIfGif = async (id) => {
      try {
        const { sourceType } = await fetchVideo(id);
        setIsGif(sourceType === 2);
      } catch (e) {
        Error(`Error validating file type: ${(e as any).message}`);
      } finally {
        setLoading(false);
      }
    };

    if (currentVideoId) {
      fetchIfGif(currentVideoId);
    }
  }, [currentVideoId]);

  if (loading) {
    return (
      <div className="loader-container">
        <CircleLoader size="large" colorMode="dark" />
      </div>
    );
  }

   return isGif ? (
     <ShareGifPage videoId={currentVideoId as any} />
   ) : (
     <ShareVideoApp videoId={currentVideoId as any} />
   );
};

export default SharePage;
